<script lang="ts">
export default {
    name: 'IconInstagram',
    props: {
        /**
         * Width
         */
        width: {
            type: String,
            default: '31px',
            required: false,
        },
        /**
         * Height
         */
        height: {
            type: String,
            default: '32px',
            required: false,
        },
    },
};
</script>
<template>
    <svg
        :style="{
            height: height,
            width: width,
        }"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 31 32"
        fill="currentColor">
        <path
            fill="none"
            d="M0 .4h31v31H0z" />
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M15.5.4a15.5 15.5 0 1 0 0 31 15.5 15.5 0 0 0 0-31Zm9.65 19.47c-.04.82-.2 1.63-.51 2.4a4.96 4.96 0 0 1-2.82 2.83c-.76.28-1.55.43-2.36.45-1.03.05-1.36.05-3.98.05-2.63 0-2.92-.05-4-.05a7.44 7.44 0 0 1-2.4-.55 4.51 4.51 0 0 1-2.77-2.78 7.56 7.56 0 0 1-.46-2.35c-.05-1.04-.05-1.36-.05-3.99s0-2.96.05-3.99c.03-.82.2-1.63.5-2.4a4.51 4.51 0 0 1 2.78-2.78 7.44 7.44 0 0 1 2.36-.45c1.03-.05 1.36-.05 3.99-.05 2.62 0 2.95 0 3.98.05.85.02 1.69.19 2.48.5a4.76 4.76 0 0 1 2.75 2.8c.28.75.43 1.55.46 2.35 0 1.03.05 1.37.05 3.99s-.05 2.89-.05 3.97Z" />
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M22.98 10.16a2.93 2.93 0 0 0-.73-1.07c-.3-.32-.66-.57-1.07-.74a5.58 5.58 0 0 0-1.8-.4H15.5c-2.58 0-2.89 0-3.92.08a5.5 5.5 0 0 0-1.8.34c-.41.15-.79.4-1.1.72-.32.3-.57.66-.73 1.07-.2.58-.32 1.2-.32 1.8v7.84c0 .61.12 1.22.33 1.8.16.4.4.77.72 1.07.3.32.67.57 1.07.73.58.2 1.2.32 1.81.32 1.02.06 1.33.06 3.9.06 2.59 0 2.85-.06 3.92-.06a5.58 5.58 0 0 0 1.8-.33c.8-.35 1.45-1 1.8-1.8.21-.58.32-1.2.33-1.81.05-1.02.05-1.33.05-3.91s-.05-2.9-.05-3.91a5.5 5.5 0 0 0-.33-1.8Zm-7.48 10.7a4.96 4.96 0 1 1 0-9.92 4.96 4.96 0 0 1 0 9.93Zm5.28-8.84a1.18 1.18 0 1 1 0-2.36 1.18 1.18 0 0 1 1.2 1.14v.03A1.18 1.18 0 0 1 20.75 12l.03.01Z" />
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M15.5 12.65a3.23 3.23 0 1 0-.06 6.46 3.23 3.23 0 0 0 .06-6.46Z" />
    </svg>
</template>
